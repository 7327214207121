<template>
  <div>
    <!-- <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.219"
      height="14.219"
      viewBox="0 0 14.219 14.219"
    >
      <path
        id="Path_15204"
        data-name="Path 15204"
        d="M15.639,3H4.58A1.58,1.58,0,0,0,3,4.58V15.639a1.58,1.58,0,0,0,1.58,1.58H15.639a1.58,1.58,0,0,0,1.58-1.58V4.58A1.58,1.58,0,0,0,15.639,3ZM8.53,14.059l-3.95-3.95L5.694,9,8.53,11.824l6-6L15.639,6.95Z"
        transform="translate(-3 -3)"
        :fill="completion ? colour : noColour"
      />
    </svg> -->

    <svg xmlns="http://www.w3.org/2000/svg" width="20.052" height="20.052" viewBox="0 0 20.052 20.052">
      <path
        id="Path_15207"
        data-name="Path 15207"
        d="M12.026,2A10.026,10.026,0,1,0,22.052,12.026,10.026,10.026,0,0,0,12.026,2Zm0,18.619a8.594,8.594,0,0,1,0-17.187v8.594L18.1,18.1A8.567,8.567,0,0,1,12.026,20.619Z"
        transform="translate(-2 -2)"
        :fill="completion ? colour : noColour"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    completion: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: false,
      colour: "#ec1c65",
      noColour: "rgba(255, 255, 255, 0.5)",
    };
  },
};
</script>
